import React, { Component } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import sf from './delivery.svg';
import { Image } from 'react-bootstrap';
import sf2 from './details.svg';
import sf1 from './support.svg';
import '../App.css'
//import mypic from './mygif.gif'

export default class Cour extends Component {
    render() {
        return (
            <><div>
                
                <Carousel className='fluidm5'>
                    <Carousel.Item>
                        <Image alt='DELIVER EXTEPNCE' className='fluidm5' src={sf} />
                        <Carousel.Caption className='fluidm1'>
                            <div className='' rounded><h3 className='colorpl'>Goods Delivery:</h3></div>
                            <div className='bgdr1'> <p className=''>We understand the importance of a timely and secure supply chain. That's why we take care of delivering the required goods to your franchise location with utmost precision and efficiency. Whether it's fresh ingredients, merchandise, or equipment, we ensure that you have what you need when you need it.</p></div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image alt='DELIVER EXTEPNCE' className='fluidm5' src={sf1} />
                        <Carousel.Caption className='fluidm1'>
                            <h3 className='colorpl'>Setup and Support:</h3>
                            <div className='bgdr1 '><p className=''>Setting up a new franchise can be a daunting task, but with Shaan Ki Chai, you're never alone. Our team of experts is here to assist you with the setup process, ensuring that your franchise location is ready to welcome customers. From interior design to equipment installation, we've got it all covered.</p></div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item >
                        <Image alt='DELIVER EXTEPNCE' className='fluidm5' src={sf2} />
                        <Carousel.Caption className='fluidm1'>
                            <h3 className='colorpl'>Marketing Management:</h3>
                            <div className='bgdr1 '><p className=''>Successful franchises rely on effective marketing strategies. We've got your back in this department too. Our experienced marketing team will work closely with you to develop and implement marketing campaigns that drive growth and customer engagement. From social media management to local advertising, we tailor our approach to meet your unique needs.</p></div>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            </>
        )
    }
}

import { createHashRouter } from "react-router-dom";
import Home from './sources/home.js'
import About from './sources/about.js'
import Root from './Root.js'
import './App.css';
import Contact from "./sources/Contact.js";



const router = createHashRouter(
  [
    {
      path: "/",
      element: <Root />,
      children: [
        {
          path: "/",
          element: <Home />,
          index: true
        },
        {
          path: "/about",
          element: (
            <>
              <About />
            </>
          ),
          index: false
        },
        {
          path: "/Contact",
          element: (
            <>
            <Contact/>
            </>
          ),
          index: false
        }
      ]
    }
  ]
);

export default router;
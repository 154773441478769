import React from 'react'
import {  Outlet } from 'react-router-dom'
import NavScrollExample from './sources/nav'
import CardFooterf from './sources/footer'

const Root = () => {
    return (
        <div className='bl'>
            <NavScrollExample/>
            <hr/>
            
            <Outlet />
            <CardFooterf/>

        </div>
    )
}

export default Root
import React, { useState } from 'react';
//import axios from 'axios';
//import $ from 'jquery';
//import fetch from 'fetch';
//import express from 'express';
//import cors from 'cors';


//const app = express();

//cors();


const DataForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    num: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  //const handleSubmit = async (e) => {
   // e.preventDefault();

    const { name, email, message, num } = formData;

    // Encode data for URL parameters
    const encodedName = encodeURIComponent(name);
    const encodedEmail = encodeURIComponent(email);
    const encodedMessage = encodeURIComponent(message);
    const encodednum = encodeURIComponent(num);

    const url = `https://script.google.com/macros/s/AKfycbzHpKKTtk2r60p8ZGDovvoU_2Kr92JpgK2gx9dFOMvpZnT6ZCCYmPgptXKLf-9S42oyVw/exec?MOBILE NO=${encodednum}&FULL NAME=${encodedName}&GMAIL=${encodedEmail}&Message=${encodedMessage}`;
    const handleSubmit = async (e) => {
      e.preventDefault();
    
      // ...
    
      // Redirect to the specified URL after submitting the form
      window.location.href = url;
    
      // Reset the form (if needed)
      setFormData({
        name: '',
        email: '',
        message: '',
        num: '',
      });
    };

    /*try {
     /*} const response1 = await fetch(url, {
        method: "GET",
        mode: "cors",  // Change the mode to CORS  
        headers: {
          "Access-Control-Allow-Credentials" : "*"
        },
      });
    console.log(response1.json());*/
    /*const headers = {
      'Access-Control-Allow-Origin': '*', // Replace '*' with your allowed origins
      'Access-Control-Allow-Methods': 'GET,POST',
      'Access-Control-Allow-Headers': 'Content-Type',
    };
    /*$.ajax({
      type: 'POST',
      url:url,
      headers: headers,
      success: function (response) {
        // Handle the response here
        console.log('POST request successful', response);
      },*/
     /* error: function (error) {
        // Handle errors here
        console.error('POST request error', error);
      },
    });*/
     /* const response1 = await axios.post(url,
        {
          //method: "GET,POST",
          //mode: "cors",
          headers: {
            "Access-Control-Allow-Credentials" : "*"

          },
        });
      console.log(response1.data);*/
      // Reset the form
   /*   setFormData({
        name: '',
        email: '',
        message: '',
      });*/
   /* } catch (error) {
      console.error('Error submitting data:', error);
    }*/
 // };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <input
            className="form-control"
            type="tel"
            name="num"
            value={formData.num}
            onChange={handleChange}
            placeholder="Mobile Number"
            required
          />
        </div>
        <div className="mb-3">
          <input
            className="form-control"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Name"
          />
        </div>
        <div className="mb-3">
          <input
            className="form-control"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
          />
        </div>
        <div className="mb-3">
          <textarea
            className="form-control"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="6"
            placeholder="Message"
          />
        </div>
        <div>
          <button className="btn btn-primary shadow d-block w-100" type="submit">
            Send
          </button>
        </div>
      </form>
    </div>
  );
};

export default DataForm;
